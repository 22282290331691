import { h, JSX } from 'preact';
import { useState } from 'preact/hooks';

export function ImageHeaderWithFallback({
  backgroundColor,
  ...props
}: JSX.HTMLAttributes<HTMLImageElement> & { backgroundColor: string }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  return (
    <div
      style={{
        backgroundColor,
        ...(loading || hasError ? { display: 'none' } : {}),
      }}
      className="mateo-justify-left mateo-flex mateo-px-6"
    >
      <img
        alt="Logo"
        onError={() => setHasError(true)}
        onLoad={() => setLoading(false)}
        className="mateo-h-12 mateo-w-12 mateo-cursor-pointer mateo-rounded-sm"
        {...props}
      />
    </div>
  );
}
